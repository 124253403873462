import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/base/layout-cases.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`{props.pageContext.frontmatter.title}`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0f97d61e2c03ea7bfdc42cccfa0911ee/07a9c/brandishipsum-webdesign-showcase.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.16564417177914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiUlEQVQ4y22UW3OlRBSF+f8/wif1QR1nrLn4ppYmNVHHSiY5JJncOdwvp6GBA3TD4bO6OUlmLHfVgi6K+nqtzW4cJUMWRWgZo6oIVcWLSnNPUDJFyQwlc5QsUHKDkgJdV+hGouoa3TRWjgENe2AnfNpkxdYovUCJG5S4Q5UPqGqNqnx0FdKLgKGM6EXCUAl0Uz9Bnb6K6KqIXsbUxZpNuKKKXYrgjPPj3/h0esjlx0OuV0fcXXzAuzqhKXy6MmYrYjpRfOHSacuIxipG5D6ef0MQ3uL7Nxwe/srBwS+8f/87f/15wNHRH5wc/40oEmRZIEVBJ3IbXdn4NU4lYsTGKCFNA07vA1ZexMU65DpMuE8yvDTHzwrCYkNcCFJRkVcNm6qmKw2wtEDVSJysiEnyhDhPCJOIXml2MzTtlo0oaduWru/pB4XSI7t5tppnGMeRrszQtUDJ0jp1wixhnaR4Scp9FDNNI6amcaRuWjZyS9N2DEPPNE0sNdvrbnoE7r+6LHEekpSbKOU6yrgKEvS4AM3uu91s3T7WbJ0tsptaYIqul1EyTh0DuwwzzoMMd/0MHIaBpmkYp4lxmtntdvy3FmCCrpf5NE4dA3ODjDM/59RLn4B93yNlTbvvZVmWbLed7alS6n+AuXXqnAe5hX1c55x4KUpr+/K56/Lq5QvevX3Nz2/fWL1785pXP77AXZ3t26LtPOp6OUVaPgLXnwH3DkUHXgVhDV458SBGu/YlyOGzyCJGm6NZZ2iZ41wEOSsTdx/ZjIapJN/gfrrl8vbhC7lXd6T55mkSOhEtQJlap85VlGGgrgGvnyOfnfzDd998xU8/fMvL77+2Mmvz7PT4w3NkC1x+IFpm/Av+mRtzYUcqnwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "showcase image of brandish ipsum",
            "title": "showcase image of brandish ipsum",
            "src": "/static/0f97d61e2c03ea7bfdc42cccfa0911ee/a6d36/brandishipsum-webdesign-showcase.png",
            "srcSet": ["/static/0f97d61e2c03ea7bfdc42cccfa0911ee/222b7/brandishipsum-webdesign-showcase.png 163w", "/static/0f97d61e2c03ea7bfdc42cccfa0911ee/ff46a/brandishipsum-webdesign-showcase.png 325w", "/static/0f97d61e2c03ea7bfdc42cccfa0911ee/a6d36/brandishipsum-webdesign-showcase.png 650w", "/static/0f97d61e2c03ea7bfdc42cccfa0911ee/e548f/brandishipsum-webdesign-showcase.png 975w", "/static/0f97d61e2c03ea7bfdc42cccfa0911ee/3c492/brandishipsum-webdesign-showcase.png 1300w", "/static/0f97d61e2c03ea7bfdc42cccfa0911ee/07a9c/brandishipsum-webdesign-showcase.png 1440w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We built a Chrome extension that would allow us to quickly generate secure passwords. This side was a fun side project that we used to enhance our skills build Chrome extensions. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      